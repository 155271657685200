<template>
  <!--  TODO -->
  <div :class="'alert alert-' + variant" role="alert">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    variant: {
      type: String,
      default: 'success',
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style scoped></style>
